// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accesorios-js": () => import("./../../../src/pages/accesorios.js" /* webpackChunkName: "component---src-pages-accesorios-js" */),
  "component---src-pages-bomberos-js": () => import("./../../../src/pages/bomberos.js" /* webpackChunkName: "component---src-pages-bomberos-js" */),
  "component---src-pages-detectores-js": () => import("./../../../src/pages/detectores.js" /* webpackChunkName: "component---src-pages-detectores-js" */),
  "component---src-pages-el-taller-js": () => import("./../../../src/pages/el-taller.js" /* webpackChunkName: "component---src-pages-el-taller-js" */),
  "component---src-pages-equipo-taller-js": () => import("./../../../src/pages/equipo-taller.js" /* webpackChunkName: "component---src-pages-equipo-taller-js" */),
  "component---src-pages-espumas-js": () => import("./../../../src/pages/espumas.js" /* webpackChunkName: "component---src-pages-espumas-js" */),
  "component---src-pages-gabinetes-js": () => import("./../../../src/pages/gabinetes.js" /* webpackChunkName: "component---src-pages-gabinetes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moviles-amerex-js": () => import("./../../../src/pages/moviles-amerex.js" /* webpackChunkName: "component---src-pages-moviles-amerex-js" */),
  "component---src-pages-moviles-js": () => import("./../../../src/pages/moviles.js" /* webpackChunkName: "component---src-pages-moviles-js" */),
  "component---src-pages-portatiles-amerex-js": () => import("./../../../src/pages/portatiles-amerex.js" /* webpackChunkName: "component---src-pages-portatiles-amerex-js" */),
  "component---src-pages-portatiles-js": () => import("./../../../src/pages/portatiles.js" /* webpackChunkName: "component---src-pages-portatiles-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-sistemas-js": () => import("./../../../src/pages/sistemas.js" /* webpackChunkName: "component---src-pages-sistemas-js" */),
  "component---src-templates-products-template-js": () => import("./../../../src/templates/products-template.js" /* webpackChunkName: "component---src-templates-products-template-js" */)
}

